import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

const Footer = (props) => {
  const { menuLinks } = props.data.site.siteMetadata;
  return (
    <div className="footer-strip">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="footer">
              <h3 className="footer-title">{props.data.site.siteMetadata.title}</h3>
              <ul className="footer-menu">
                <li key="home">
                  <Link to="/">Startseite</Link>
                </li>
                {menuLinks.map(link => (
                  <li key={link.name}>
                    <Link to={link.link}>
                      {link.name}
                    </Link>
                  </li>
                ))}
                <li className="copyright">
                  ©
                  {' '}
                  {new Date().getFullYear()}
                  {' '}
                  {props.data.site.siteMetadata.title}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
            menuLinks {
              name
              link
            }
          }
        }
      }
    `}
    render={data => <Footer data={data} />}
  />
);
